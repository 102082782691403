import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { encryptUsingAES256 } from "../../helpers/utils";

const initialBoqState = {
  isLoading: false,
  isError: false,
  boqData: null,
};

const getBoqSlice = createSlice({
  name: "GetBoq",
  initialState: initialBoqState,
  reducers: {
    apiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.boqData = null;
    },

    apiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.boqData = action.payload;
    },

    apiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.boqData = null;
    },
    
    resetBoqData(state) {
      state.isLoading = false;
      state.isError = false;
      state.boqData = null;
    },
  },
});

export const getBoqAction = (orderId) => {
  return (dispatch) => {
    dispatch(getBoqDataAction.apiRequested());
    makeHttpRequest({
      method: "get",
      url: `/get-boq-json?orderId=${encryptUsingAES256(JSON.stringify(orderId))}&boqType=${encryptUsingAES256("ALL")}&requestType=${encryptUsingAES256("BOQ")}
      `,
    })
      .then((res) => {
        dispatch(getBoqDataAction.apiSuccess(res?.data));
      })
      .catch((err) => {
        dispatch(getBoqDataAction.apiFailure(err));
      });
  };
};

export const resetBoqDataFromComponent = () => (dispatch) => {
  dispatch(getBoqDataAction.resetBoqData());
};

export const getBoqDataAction = getBoqSlice.actions;
export default getBoqSlice.reducer;
